import axios from "axios";
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { DisplayLoader } from "../../utils/loader";
import { ErrorDisplay } from "../../modules/components/common/ErrorDisplay";
import { Link, useParams } from "react-router-dom";
import { Challenge } from "../challenge/Challenge";

type ApiParams = { batchId: string };
export const BatchHome = () => {
  const { batchId } = useParams<ApiParams>();
  let [batchName, setBatchName] = useState<string>('');
  let [folders, setFolders] = useState<any>({ folderStructure: [] });
  let [folderSequence, setFolderSequence] = useState<any>([]);
  let [dashboardState, setDashboardState] = useState<string>('lessons');
  let [dashboardSubState, setDashboardSubState] = useState<string>('active');
  let [activeChallenges, setActiveChallenges] = useState<any>([]);
  let [upcomingChallenges, setUpcomingChallenges] = useState<any>([]);
  let [completedChallenges, setCompletedChallenges] = useState<any>([]);
  let [addState, setAddState] = useState<any>(1); //0-default,1-add folder,2-add challenge
  let [addIndex, setAddIndex] = useState<any>(0);
  let [addIndices, setAddIndices] = useState<any>([]);
  let [showAddModal, setShowAddModal] = useState<boolean>(false);
  let [showScheduleNewModal, setShowScheduleNewModal] = useState<boolean>(false);
  let [searchedChallenges, setSearchedChallenges] = useState<any>([]);
  let validationSchemaFolder = Yup.object({
    name: Yup.string().required().label('Name'),
  });
  useEffect(() => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch/' + batchId).then((reply) => {
      if (reply?.data?.name) {
        setBatchName(reply.data.name)
      }
    }).catch(() => {
      //TODO
    })
    axios.get(process.env.REACT_APP_API_URL + '/get-folder-structure/' + batchId).then((reply) => {
      if (reply?.data?.folders) {
        setFolders([reply.data.folders]);
        setFolderSequence([reply.data.folders]);
      } else {
        setFolders([{ folderStructure: [] }]);
        setFolderSequence([{ folderStructure: [] }]);
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      DisplayLoader(false, '')
      //TODO
    })
  }, [])
  const listFolders = (folders: any, level: number, parent: string, indices: any) => {
    return <>
      {folders.folderStructure?.map((folder: any, index: number) => {
        return <div className="accordion-item shadow mb-1 border-top border-dark">
          <h2 className="accordion-header" id={index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level}>
            <div className="ps-2 bg-light-dark">
              {/*<button className="ps-2 pe-2 p-0 me-3 btn btn-primary"><i className="fa fa-caret-left p-0" /></button>*/}
              <button className="ps-2 pe-2 p-0 me-3 btn btn-primary" onClick={() => { moveFolder(level, index, true, indices) }}><i className="fa fa-caret-up p-0" /></button>
              <button className="ps-2 pe-2 p-0 me-3 btn btn-primary" onClick={() => { moveFolder(level, index, false, indices) }}><i className="fa fa-caret-down p-0" /></button>
              <button className="ps-2 pe-2 p-0 me-3 btn btn-danger float-end" onClick={() => { deleteFolder(level, index, indices) }}><i className="fa fa-trash p-0" /></button>
            </div>
            <button className="accordion-button collapsed p-3" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level} aria-expanded="true" aria-controls={"collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level}>
              <h5>{folder.name}</h5>
            </button>
          </h2>
          <div id={"collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level} className="accordion-collapse collapse" aria-labelledby={index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level} data-bs-parent={"#" + parent}>
            <div className="accordion-body">
              {listFolders(folder, (level + 1), "collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level, [...indices, ...[index]])}
            </div>
          </div>
        </div>
      })}
      {folders.challengeInstances?.map((folder: any, index: number) => {
        return <div className="accordion-item shadow mb-2 border-top border-dark">
          <h2 className="accordion-header" id={index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level}>
            <div className="ps-2 mb-1 bg-light-dark">
              {/*<button className="ps-2 pe-2 p-0 me-3 btn btn-primary"><i className="fa fa-caret-left p-0" /></button>*/}
              <button className="ps-2 pe-2 p-0 me-3 btn btn-primary" onClick={() => { moveChallenge(level, index, true, indices) }}><i className="fa fa-caret-up p-0" /></button>
              <button className="ps-2 pe-2 p-0 me-3 btn btn-primary" onClick={() => { moveChallenge(level, index, false, indices) }}><i className="fa fa-caret-down p-0" /></button>
              <button className="ps-2 pe-2 p-0 me-3 btn btn-danger float-end" onClick={() => { deleteChallenge(level, index, indices) }}><i className="fa fa-trash p-0" /></button>
            </div>
            <Link to={'/lesson/' + folder.id} style={{ width: "100%" }}>
              <h5 className="text-primary m-4"><i className="fa fa-book me-3 text-info"></i>{folder.name}</h5>
            </Link>
          </h2>
        </div>
      })}
      <button className="btn btn-primary p-2 m-1" onClick={() => { setAddState(0); setAddIndex(level); setAddIndices(indices); setShowAddModal(true) }}>+</button>
    </>
  }
  const getChallengeInstances = (type: string) => {
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-instances-by-batch/' + batchId + '/' + type).then((reply) => {
      console.log("here")
      if (reply?.data?.challengeInstances) {
        setCompletedChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.end) < new Date() || (challenge.attemptDetails.length > 0 && challenge.attemptDetails[0].completed)
        }).sort(function (a: any, b: any) {
          if (!b.date) return -1
          return Date.parse(b.end) - Date.parse(a.end);
        }));
        setUpcomingChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.start) > new Date()
        }).sort(function (a: any, b: any) {
          if (!b.date) return 1
          return Date.parse(a.end) - Date.parse(b.end);
        }));
        setActiveChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.start) < new Date() && new Date(challenge.end) > new Date() && (challenge.attemptDetails.length === 0 || !challenge.attemptDetails[0].completed)
        }).sort(function (a: any, b: any) {
          if (!b.date) return 1
          return Date.parse(a.end) - Date.parse(b.end);
        }));
      } else {
        //TODO
      }
    })
  }
  const addFolder = (values: FormikValues) => {
    let newFolderSequence = [...folderSequence]
    let temp: any = newFolderSequence
    let index = addIndex;
    let indices = [...addIndices]
    indices.reverse()
    while (index > -1) {
      if (index === 0) {
        if (!temp.folderStructure && !temp[0]) {
          temp.folderStructure = []
        }
        if (!temp.folderStructure) {
          temp[0].folderStructure.push({ name: values.name })
        } else {
          temp.folderStructure.push({ name: values.name })
        }
      } else {
        let i = indices.pop()
        if (!temp[0]) {
          temp = [temp]
        }
        if (!temp[0].folderStructure) {
          temp[0].folderStructure = []
        }
        temp = temp[0].folderStructure[i];
      }
      index--;
    }
    //let newFolders = { ...folders };
    //newFolders.folderStructure = newFolderSequence[0];
    setFolders(newFolderSequence);
    setFolderSequence(newFolderSequence);
    setShowAddModal(false);
    console.log(newFolderSequence)
  }
  const moveFolder = (level: number, index: number, toUp: boolean, indices: any) => {
    let newFolderSequence = [...folderSequence]
    let temp: any = newFolderSequence;
    indices.reverse()
    while (level > -1) {
      if (level === 0) {
        if (toUp && index === 0 || !toUp && index === temp[0].folderStructure.length - 1) return;
        if (!temp.folderStructure) {
          temp = temp[0]
        }
        const val = temp.folderStructure[index];
        temp.folderStructure.splice(index, 1);
        temp.folderStructure.splice(index + (toUp ? -1 : 1), 0, val);
      } else {
        let i = indices.pop()
        if (!temp[0]) {
          temp = [temp]
        }
        if (!temp[0].folderStructure) {
          temp[0].folderStructure = []
        }
        temp = temp[0].folderStructure[i];
      }
      level--;
    }
    setFolders(newFolderSequence);
    setFolderSequence(newFolderSequence);
  }
  const deleteFolder = (level: number, index: number, indices: any) => {
    console.log("here")
    let newFolderSequence = [...folderSequence]
    let temp: any = newFolderSequence
    indices.reverse()
    while (level > -1) {
      if (level === 0) {
        if (!temp.folderStructure) {
          temp[0].folderStructure.splice(index, 1);
        } else {
          temp.folderStructure.splice(index, 1);
        }
      } else {
        let i = indices.pop()
        if (!temp[0].folderStructure) {
          temp[0].folderStructure = []
        }
        temp = temp[0].folderStructure[i];
      }
      level--;
    }
    setFolders(newFolderSequence);
    setFolderSequence(newFolderSequence);
  }
  const addChallenge = (challenge: any) => {
    let newFolderSequence = [...folderSequence]
    let temp: any = newFolderSequence
    temp = temp[0]
    let index = addIndex + 1;
    let indices = [...addIndices]
    indices.reverse()
    while (index > -1) {
      if (index === 0) {
        axios.post(process.env.REACT_APP_API_URL + '/create-challenge-instance/', { challengeId: challenge._id, type: 'lesson', batchId: batchId }).then((reply) => {
          if (reply?.data?.challengeInstanceId) {
            temp.push({ name: challenge.heading, id: reply.data.challengeInstanceId })
          } else {
            //TODO
          }
          setFolders(newFolderSequence);
          setFolderSequence(newFolderSequence);
          setShowAddModal(false);
          console.log(newFolderSequence)
        })
      } else {
        if (index === 1) {
          if (!temp.challengeInstances) {
            temp.challengeInstances = []
          }
          temp = temp.challengeInstances;
        } else {
          let i = indices.pop()
          temp = temp.folderStructure[i];
        }
      }
      index--;
    }
  }
  const searchChallenges = (type : string) => {
    let searchText = (document.getElementById('searchText') as HTMLInputElement).value;
    if (searchText.length < 3) return
    axios.post(process.env.REACT_APP_API_URL + '/search-challenges/', { searchText: searchText, type: type }).then((reply) => {
      if (reply?.data) {
        setSearchedChallenges(reply.data)
        //updateChallenge(sectionPosition);
      } else {

      }
    }, (error) => {
      //reject(error)//TODO
    });
  }
  const moveChallenge = (level: number, index: number, toUp: boolean, indices: any) => {
    let newFolderSequence = [...folderSequence]
    let temp: any = newFolderSequence
    temp = temp[0]
    indices.reverse();
    while (level > -1) {
      if (level === 0) {
        if (toUp && index === 0 || !toUp && index === temp[0].challengeInstances.length - 1) return;
        const val = temp.challengeInstances[index];
        temp.challengeInstances.splice(index, 1);
        temp.challengeInstances.splice(index + (toUp ? -1 : 1), 0, val);
      } else {
        let i = indices.pop()
        temp = temp.folderStructure[i];
      }
      level--;
    }
    setFolders(newFolderSequence);
    setFolderSequence(newFolderSequence);
  }
  const deleteChallenge = (level: number, index: number, indices: any) => {
    let newFolderSequence = [...folderSequence]
    let temp: any = newFolderSequence
    indices.reverse()
    temp = temp[0]
    while (level > -1) {
      if (level === 0) {
        temp.challengeInstances.splice(index, 1);
      } else {
        let i = indices.pop()
        temp = temp.folderStructure[i];
      }
      level--;
    }
    setFolders(newFolderSequence);
    setFolderSequence(newFolderSequence);
  }
  const saveFolderStructure = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-folder-structure/', { batchId: batchId, folderStructure: folders[0].folderStructure }).then((reply) => {
      if (reply?.data?.folders) {
        setFolders([reply.data.folders]);
        setFolderSequence([reply.data.folders]);
      } else {
        setFolders([{ folderStructure: [] }]);
        setFolderSequence([{ folderStructure: [] }]);
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      DisplayLoader(false, '')
      //TODO
    })
  }
  return (
    <div className="ms-2 me-2 pb-15 p-2 shadow rounded">
      <h2 className="text-center">{batchName}</h2>
      <h6 className='mb-0'>
        <div onClick={() => { setDashboardState('lessons') }} className={(dashboardState === 'lessons' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Lessons</div>
        <div onClick={() => { setDashboardState('assignments'); getChallengeInstances('assignment') }} className={(dashboardState === 'assignments' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Assignments</div>
        <div onClick={() => { setDashboardState('tests'); getChallengeInstances('test') }} className={(dashboardState === 'tests' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Tests</div>
      </h6>
      <div className='border-top border-dark p-2 shadow pe-4'>
        {dashboardState === 'lessons' && <div>
          <div className="accordion animate__animated animate__fadeIn" id="lessonList">
            {folders && folders.length > 0 && listFolders(folders[0], 0, 'lessonList', [])}
          </div>
        </div>}
        {(dashboardState === 'assignments' || dashboardState === 'tests') && <>
          <h5 className='text-center mb-4'>{dashboardState === 'assignments' ? 'Assignments' : 'Tests'}</h5>
          <h6 className='mb-0'>
            <div onClick={() => { setDashboardSubState('active') }} className={(dashboardSubState === 'active' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Active</div>
            <div onClick={() => { setDashboardSubState('upcoming') }} className={(dashboardSubState === 'upcoming' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Upcoming</div>
            <div onClick={() => { setDashboardSubState('completed') }} className={(dashboardSubState === 'completed' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Completed</div>
          </h6>
          <div className='border-top border-dark p-2 shadow pe-4'>
            <div className='mt-3'>
              {dashboardSubState === 'active' &&
                <div>
                  {activeChallenges.map((challenge: any, index: number) => {
                    return <Link to={'/create-module/' + challenge.challengeId} className='p-3 shadow card mb-3 text-dark animate__animated animate__fadeIn' key={index + challenge.challengeDetails[0].heading + dashboardState}><h5>{challenge.challengeDetails[0].heading}</h5>
                      <div className='d-inline-block' style={{ fontSize: '10px' }}>Ends On - <b>{new Date(challenge.end).toLocaleString()}</b></div>
                      {dashboardState === 'tests' && <div className='float-end d-inline-block' style={{ fontSize: '10px' }}>Total Time - <b>{challenge.totalTime} Mins</b></div>}
                    </Link>
                  })}
                </div>
              }
              {dashboardSubState === 'upcoming' &&
                <div>
                  {upcomingChallenges.map((challenge: any, index: number) => {
                    return <div className='p-3 shadow card d-flex mb-3 text-dark animate__animated animate__fadeIn' key={index + challenge.challengeDetails[0].heading + dashboardState}><h5>{challenge.challengeDetails[0].heading}</h5>
                      <span style={{ fontSize: '10px' }}>Starts On - {new Date(challenge.start).toLocaleString()}</span>
                    </div>
                  })}
                </div>
              }
              {dashboardSubState === 'completed' &&
                <div>
                  {completedChallenges.map((challenge: any, index: number) => {
                    return <Link to={'/composite-reports/' + challenge._id} className='p-3 shadow card mb-3 text-dark animate__animated animate__fadeIn' key={index + challenge.challengeDetails[0].heading + dashboardState}>
                      <h5>{challenge.challengeDetails[0].heading}</h5>
                    </Link>
                  })}
                </div>
              }
            </div>
          </div>
        </>}
      </div>
      {dashboardState === 'lessons' && <button className="btn btn-primary float-end" onClick={() => { saveFolderStructure() }}>Save</button>}
      {(dashboardState === 'assignments' || dashboardState === 'tests') && <button type="button" className="btn btn-primary float-end" onClick={() => { setShowScheduleNewModal(true) }}>Select {dashboardState[0].toUpperCase() + dashboardState.slice(1, dashboardState.length - 1)}</button>}
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showAddModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowAddModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Add</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            {
              addState === 0 && <div className="text-center">
                <button type="button" className="btn btn-primary" onClick={() => { setAddState(1) }}>Add Folder</button><br />
                <button type="button" className="btn btn-primary mt-5" onClick={() => { setAddState(2) }}>Add Module</button>
              </div>
            }
            {
              addState === 1 && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchemaFolder} initialValues={{}} onSubmit={addFolder}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Name</label></div>
                    <div>
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='name' placeholder='Name' />
                      {ErrorDisplay('name', errors)}
                    </div>
                    <button className="btn btn-primary float-right">Add</button>
                  </Form>
                )}
              </Formik>
            }
            {
              addState === 2 && <div>
                <input id="searchText" type="text" onChange={(e) => { searchChallenges('lesson') }} className="form-control" placeholder="Your query...." aria-label="Query" aria-describedby="query" />
                <div style={{ fontSize: "10px" }}>Type atleast 3 letters to start search</div>
                <div>
                  {
                    searchedChallenges.map((challenge: any) => {
                      return <div className="border border-dark p-2 rounded d-flex justify-content-between">
                        {challenge.heading}
                        <button type="button" className="btn btn-dark" onClick={() => { addChallenge(challenge) }}>Add</button>
                      </div>
                    })
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showScheduleNewModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowScheduleNewModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Select Test</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            {
              <div>
                <input id="searchText" type="text" onChange={(e) => { searchChallenges(dashboardState.slice(0, dashboardState.length - 1)) }} className="form-control" placeholder="Your query...." aria-label="Query" aria-describedby="query" />
                <div style={{ fontSize: "10px" }}>Type atleast 3 letters to start search</div>
                <div>
                  {
                    searchedChallenges.map((challenge: any) => {
                      return <div className="border border-dark p-2 rounded d-flex justify-content-between">
                        {challenge.heading}
                        <Link to={"/create-module/" + challenge._id} type="button" className="btn btn-dark" >Select</Link>
                      </div>
                    })
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
    </div>
  )
}