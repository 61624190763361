import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import clsx from 'clsx';
import Latex from 'react-latex-next';
import { FirebaseUpload } from '../utils/FirebaseUpload';
import makeId from '../../utils/makeId';
import { generateOMRCorrectionTemplate } from '../../utils/generateOMRCorrectionTemplate';
type ApiParams = { id: string };
const CompositeReports: FC = () => {
  const { id } = useParams<ApiParams>();
  const [tab, setTab] = useState('participants')
  let [report, setReport] = useState<any>({});
  useEffect(() => {
    getReports();
  }, []);
  const uploadOMR = (event: any, index?: number, downloadLinks?: string[]) => {
    if (!index) index = 0;
    if (!downloadLinks) downloadLinks = []
    if (event?.target?.files?.length > 0) {
      if (index >= event.target.files.length) {
        DisplayLoader(true, 'Analysing OMRs. This may take a while....')
        let template = generateOMRCorrectionTemplate(6, createOMRConfig())
        axios.post(process.env.REACT_APP_API_URL + '/omr-correction/' + id, { downloadLinks: downloadLinks, template: template }).then((reply) => {
          if (reply?.data) {
            window.location.reload();
          } else {
            //TODO
          }
          DisplayLoader(false, '')
        }, (error) => {
          DisplayLoader(false, '')
          //reject(error)//TODO
        });
      } else {
        DisplayLoader(true, 'Uploading file ' + (index + 1) + '/' + event?.target?.files?.length)
        let file = event.target.files[index];
        FirebaseUpload(file, '/omrs/' + id + '/' + (makeId(4) + file.name), function (downloadLink: string) {
          if (downloadLinks) downloadLinks.push(downloadLink)
          uploadOMR(event, (index != null ? (index + 1) : 0), downloadLinks)
        })
      }
    }
  }
  const createOMRConfig = () => {
    let OMRConfig: any = {}
    let subjectList = ['physics', 'chemistry', 'maths', 'biology']
    subjectList.forEach((subject: string) => {
      let count = report.challenge.sections.filter((section: any) => {
        return report.sectionMap[section].tags.indexOf(subject) != -1
      }).length;
      if (count > 0) {
        OMRConfig[subject] = count;
      }
    })
    return OMRConfig;
  }
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-composite-reports/' + id).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      <div >
        <h3 className='text-center m-2'>{report?.challenge?.heading}</h3>
        <div className='no-print' style={{ marginBottom: '60px' }}>
          <button className='btn btn-info float-end' onClick={() => { window.print() }}>Download</button>
        </div>
        <div>
          <div className='col-4 p-3 d-inline-block text-center'>
            <div className='text-center bg-light shadow-lg rounded p-4 pb-1'>
              <h4>{report.stats?.totalAttempts}</h4>
              <h6>Attempts</h6>
            </div>
          </div>
          <div className='col-4 p-3 d-inline-block text-center'>
            <div className='text-center bg-light shadow-lg rounded p-4 pb-1'>
              <h4>{report.stats?.highest != null && !isNaN(report.stats?.highest) ? report.stats?.highest : '-'}</h4>
              <h6>Highest</h6>
            </div>
          </div>
          <div className='col-4 p-3 d-inline-block text-center'>
            <div className='text-center bg-light shadow-lg rounded p-4 pb-1'>
              <h4>{report.stats?.average != null && !isNaN(report.stats?.average) ? report.stats?.average : '-'}</h4>
              <h6>Average</h6>
            </div>
          </div>
        </div>

        {report?.challenge?.presentationMode == 'offline' && <div className='no-print m-2 p-2'>
          <h5>Upload OMR Sheets</h5><input type="file" className='btn btn-info' multiple onChange={uploadOMR} accept="image/jpeg, image/png, image/gif" />
        </div>}
        <div className='no-print card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
            role='tablist'
          >
            <li className='nav-item'>
              <a className={clsx(`nav-link cursor-pointer`, { active: tab === 'participants' })} onClick={() => setTab('participants')} role='tab'>
                Participants
              </a>
            </li>
            <li className='nav-item'>
              <a className={clsx(`nav-link cursor-pointer`, { active: tab === 'sections' })} onClick={() => setTab('sections')} role='tab'>
                Sections
              </a>
            </li>
            <li className='nav-item'>
              <a className={clsx(`nav-link cursor-pointer`, { active: tab === 'summary' })} onClick={() => setTab('summary')} role='tab' >
                Summary
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body'>
          {tab === 'participants' && <div>
            <div className='col-5 d-inline-block'></div>
            <div className='col-2 d-inline-block'><h5 className='text-end pe-1'>Marks</h5></div>
            <div className='col-2 d-inline-block'><h5 className='text-end pe-1'>Rank</h5></div>
            <div className='col-3 d-inline-block'></div>
            {report.attempts?.map((attempt: any, index: number) => {
              return <div className='shadow p-3 rouded'>
                <div className='col-5 d-inline-block'>
                  <h5>{attempt.name}</h5>
                </div>
                <div className='col-2 d-inline-block text-end'>
                  <b>{attempt.totalPoints != null ? attempt.totalPoints : '-'}</b>
                </div>
                <div className='col-2 d-inline-block text-end'>
                  <b>{!attempt.completed ? '-' : (index + 1)}</b>
                </div>
                <div className='no-print col-3 d-inline-block text-end'>
                  <a href={'/reports/' + attempt.challengeInstanceId + '/' + attempt.userId} className='btn btn-dark'><i className="fa fa-chart-line"></i></a>
                </div>
                {attempt.completed && <div style={{ fontSize: "10px" }}>Submitted On - {new Date(attempt.createdAt).toLocaleString()}</div>}
                {!attempt.completed && <div style={{ fontSize: "10px" }}>*Not submitted yet</div>}
              </div>
            })}
            {
              report.participants?.map((participant: any) => {
                if (report.attemptedIds && report.attemptedIds.indexOf(participant._id) === -1) {
                  return <div className='shadow p-3 rounded'>
                    <div className='col-5 d-inline-block'>
                      <h5>{participant.name}</h5>
                    </div>
                    <div className='col-2 d-inline-block text-end'>
                      <b>-</b>
                    </div>
                    <div className='col-2 d-inline-block text-end'>
                      <b>-</b>
                    </div>
                    <div className='col-3 d-inline-block text-end'>

                    </div>
                    <div style={{ fontSize: "10px" }}>*Not Attempted</div>
                  </div>
                } else {
                  return <></>
                }
              })
            }
          </div>}
          {tab === 'sections' && <div>
            {report.challenge?.sections?.map((section: any, index: number) => {
              return <div className='shadow m-1 mb-5 p-2'>
                <h5 className='text-center'>Section {index + 1}</h5>
                <b><Latex>{report.sectionMap[section].text || ''}</Latex></b>
                {(report.sectionMap[section].type === 'mcq' || report.sectionMap[section].type === 'multiple') &&
                  <div className='ms-3 mt-2 p-2'>
                    {report.sectionMap[section].options.map((option: any, index: number) => {
                      return <div>
                        <div className='col-9 d-inline-block mb-1 border-bottom compact'>
                          <Latex>{option || ''}</Latex>
                          {(index === parseInt(report.sectionMap[section].answer) || (Array.isArray(report.sectionMap[section].answer) && report.sectionMap[section].answer.map((answer: any) => { return parseInt(answer) }).indexOf(index) !== -1)) && <i className="fa fa-check-square text-success ms-3" style={{ fontSize: '18px' }}></i>}
                        </div>
                        <div className='col-3 d-inline-block text-end border-bottom'><b>{!report.sectionAttemptMap[section] ? 0 : Math.round(report.sectionAttemptMap[section].reduce((t: number, v: any) => { return t + (v['answer'] && (parseInt(v['answer']) === index || (Array.isArray(v['answer']) && v['answer'].map(Number).indexOf(index) !== -1)) ? 1 : 0) }, 0) * 100 / report.attempts.length)}%</b></div>
                      </div>
                    })}
                    <div className='border-bottom border-danger'>
                      <div className='col-9 d-inline-block mb-1 compact'>
                        Not Attempted
                      </div>
                      <div className='col-3 d-inline-block text-end'>
                        <b>{100 - (!report.sectionAttemptMap[section] ? 0 : Math.round(report.sectionAttemptMap[section].reduce((t: number, v: any) => { return t + (v['answer'] ? 1 : 0) }, 0) * 100 / report.attempts.length))}%</b>
                      </div>
                    </div>
                  </div>
                }
                {(report.sectionMap[section].type === 'numerical' || report.sectionMap[section].type === 'long' || report.sectionMap[section].type === 'blanks' || report.sectionMap[section].type === 'blanks-any' || report.sectionMap[section].type === 'short') &&
                  <div className='ms-3 mt-2 p-2'>
                    {report.sectionAttemptMap[section] && Object.entries(report.sectionAttemptMap[section].reduce((acc: any, curr: any) => {
                      return acc[curr.answer] ? ++acc[curr.answer] : acc[curr.answer] = 1, acc
                    }, {})).map((e) => ({ [e[0]]: e[1] }))
                      .map((answer: any) => {
                        return <div className='border-bottom'>
                          <div className='col-9 d-inline-block mb-1 compact'>
                            <Latex>{Object.keys(answer)[0] || ''}</Latex>
                            {(((report.sectionMap[section].type === 'numerical' || report.sectionMap[section].type === 'long') && (Object.keys(answer)[0] === report.sectionMap[section].answer)) ||
                              (report.sectionMap[section].type === 'blanks' && Object.keys(answer)[0] === report.sectionMap[section].options?.slice(0, report.sectionMap[section].text.match(/id=\"blank([0-9])/g).length).join(',')) ||
                              (report.sectionMap[section].type === 'blanks-any' && Object.keys(answer)[0].split(',').sort() === report.sectionMap[section].options.sort()) ||
                              (report.sectionMap[section].type === 'short' && report.sectionMap[section].options?.map((option: string) => { return option.replace(/<[^>]*>?/gm, '') }).indexOf(Object.keys(answer)[0]) !== -1)) && <i className="fa fa-check-square text-success ms-3" style={{ fontSize: '18px' }}></i>}
                          </div>
                          <div className='col-3 d-inline-block text-end'><b>{Math.round(answer[Object.keys(answer)[0]] * 100 / report.attempts.length)}%</b></div>
                        </div>
                      })}
                    <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
                      <span className='bg-success p-2 mb-2'>Correct Answer</span>
                      {(report.sectionMap[section].type === 'numerical' || report.sectionMap[section].type === 'long') && <Latex>{(report.sectionMap[section].answer) || ''}</Latex>}
                      {(report.sectionMap[section].type === 'short') &&
                        <div>
                          <span className='mb-2'>Correct Answer is one of the following</span>
                          <div>
                            {report.sectionMap[section].options?.map((option: string) => {
                              return <Latex>{option || ''}</Latex>
                            })}
                          </div>
                        </div>}
                      {(report.sectionMap[section].type === 'blanks' || report.sectionMap[section].type === 'blanks-any') &&
                        <div>
                          <div className='compact'>
                            {report.sectionMap[section].options?.map((option: string, index: number) => {
                              if (index >= report.sectionMap[section].text.match(/id=\"blank([0-9])/g).length) return ''
                              return <Latex>{option + ','}</Latex>
                            })}
                            {report.sectionMap[section].type === 'blanks-any' && <span>In any order</span>}
                          </div>
                        </div>}
                    </div>
                  </div>
                }
              </div>
            })}
          </div>}
          {tab === 'summary' && <div>
            <div className='shadow m-3 rouded table-responsive'>
              <table className='table table-bordered'>
                <thead>
                  <tr><th className='border-end' style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>Name</th>
                    {report.challenge?.sections?.map((section: any, index: number) => {
                      return <td><b>{index + 1}</b></td>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {report.attempts?.map((attempt: any) => {
                    return <tr><td style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}><h5>{attempt.name}</h5>{!attempt.completed && <div style={{ fontSize: "10px" }}>*Not submitted yet</div>}</td>
                      {report.challenge?.sections?.map((section: any, index: number) => {
                        return <td>
                          {(!attempt.sections || !attempt.sections[section]) && <b>-</b>}
                          {(attempt.sections && attempt.sections[section]?.correct) && <b className='text-success'><i className="fa fa-check-square text-success" style={{ fontSize: '18px' }}></i></b>}
                          {(attempt.sections && attempt.sections[section]?.correct) === false && <b className='text-danger'><i className="fa fa-times-square text-danger" style={{ fontSize: '18px' }}></i></b>}
                        </td>
                      })}
                    </tr>
                  })}
                  {
                    report.participants?.map((participant: any) => {
                      if (report.attemptedIds && report.attemptedIds.indexOf(participant._id) === -1) {
                        return <tr><td style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}><h5>{participant.name}</h5><div style={{ fontSize: "10px" }}>*Not submitted yet</div></td>
                          {report.challenge?.sections?.map((section: any, index: number) => { return <td>-</td> })}
                        </tr>
                      } else {
                        return <></>
                      }
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

export { CompositeReports }
